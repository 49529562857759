/**
 * Copyright (C) 2020 Andrew Rioux
 * 
 * This file is part of Event Manager.
 * 
 * Event Manager is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 2 of the License, or
 * (at your option) any later version.
 * 
 * Event Manager is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * 
 * You should have received a copy of the GNU General Public License
 * along with Event Manager.  If not, see <http://www.gnu.org/licenses/>.
 */

.flightrow {
	width: 100%;
	border: 1px solid black;
	box-sizing: border-box;
	padding: 0;
	margin: 0 10px;
	border-top-width: 0px;
}

.flightrow-name {
	width: 100%;
	height: 16px;
	font-size: 14px;
	border-bottom: 1px solid black;
	font-weight: bold;
}

.flightrow-names {
	overflow: hidden;
}

.flightrow-names-open {
	min-height: 50px;
}
