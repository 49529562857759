/**
 * Copyright (C) 2020 Andrew Rioux
 * 
 * This file is part of Event Manager.
 * 
 * Event Manager is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 2 of the License, or
 * (at your option) any later version.
 * 
 * Event Manager is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * 
 * You should have received a copy of the GNU General Public License
 * along with Event Manager.  If not, see <http://www.gnu.org/licenses/>.
 */

.widget {
	min-width: 285px;
	border: 1px solid gray;
	box-shadow: 0 0 4px 0 gray;
	margin: 5px;
	margin-top: 0;
	margin-bottom: 10px;
	break-inside: avoid;
}

.widget:hover {
	box-shadow: 0 0 4px 0 #0b375b;
}

.widget .widget-title {
	border-bottom: 1px solid #f6bf22;
	color: #0b375b;
	margin: 10px;
	font-size: 1.3em;
	text-align: center;
}

.widget .widget-body {
	margin: 10px;
	margin-top: 0px;
}
