/**
 * Copyright (C) 2020 Andrew Rioux
 * 
 * This file is part of Event Manager.
 * 
 * Event Manager is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 2 of the License, or
 * (at your option) any later version.
 * 
 * Event Manager is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * 
 * You should have received a copy of the GNU General Public License
 * along with Event Manager.  If not, see <http://www.gnu.org/licenses/>.
 */
.closeButton {
	clear: both;
}

.cover {
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: fixed;
	z-index: 50;
	background-color: rgba(0, 0, 0, 0.5);
}

.alert-box {
	display: inline-block;
	background-color: white;
	color: #999999;
	box-sizing: border-box;
	padding: 20px;
	border-radius: 20px;
	box-shadow: 0 0 1em black;
	min-width: 615px;
	max-width: 90%;
	overflow: auto;
	max-height: 80%;
}
.alert-box > div {
	box-sizing: border-box;
	padding: 15px;
	margin: 0.0000000001px;
	padding-bottom: 25px;
}
.alert-box h2 {
	text-align: center;
	border-bottom: 1px solid #f6bf22;
}

@media (max-width: 1028px) {
	.cover .alert-box {
		width: 100%;
		height: 100%;
		min-width: 0;
		max-width: 100%;
		min-height: 0;
		max-height: 100%;
		border-radius: 0;
	}
}
