/**
 * Copyright (C) 2020 Andrew Rioux
 * 
 * This file is part of Event Manager.
 * 
 * Event Manager is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 2 of the License, or
 * (at your option) any later version.
 * 
 * Event Manager is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * 
 * You should have received a copy of the GNU General Public License
 * along with Event Manager.  If not, see <http://www.gnu.org/licenses/>.
 */

.datetime-input-root {
	clear: both;
	position: relative;
	padding-right: 2px;
	padding-top: 10px;
}

.datetime-input-box input,
.date-input-box input,
.time-input-box input {
	border: 1px solid black;
	height: 15px;
	padding: 5px;
	font-family: 'Courier New', Courier, monospace;
	text-align: right;
	font-size: 12px;
	line-height: 10;
}

/*
.datetime-input-root input:focus, .datetime-input-root button:focus {
	outline: none;
}

.datetime-input-root.focused > div {
	outline: 1px solid #2857d7;
	clear: both;
	overflow: auto;
}
*/

.datetime-input-seperator {
	font-family: 'Courier New', Courier, monospace;
	border-left-width: 0px;
	border-right-width: 0px;
	border-top: 1px solid black;
	border-bottom: 1px solid black;
	padding: 5px 0;
	width: 0;
	height: 15px;
	line-height: 14px;
	float: left;
}

.datetime-input-root .date-input {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	border-right-width: 0px;
	float: left;
}

.datetime-input-box .time-input {
	border-right-width: 1px;
	border-left-width: 0px;
	float: left;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	margin-right: 2px;
}

.datetime-input-gui-root {
	display: block;
	position: absolute;
	top: 30px;
	z-index: 5000;
	background-color: white;
	border: 1px solid black;
}

.datetime-input-gui-parent {
	position: relative;
}

input[type='date'],
input[type='datetime'],
input[type='time'],
input[type='datetime-local'] {
	font-family: 'RobotoRegular', Arial, Helvetica, sans-serif;
	box-sizing: border-box;
	border: 1px solid black;
	border-radius: 10px;
	padding: 5px;
	color: #0b375b;
	background: white;
	z-index: 0;
}

input[type='date']:focus,
input[type='datetime']:focus,
input[type='time']:focus,
input[type='datetime-local']:focus {
	box-shadow: inset 0 0 1em #258eea;
}

input[type='date']:disabled,
input[type='datetime']:disabled,
input[type='time']:disabled,
input[type='datetime-local']:disabled {
	background: #aaa;
}

input[class^='react-datepicker'] {
	z-index: 0;
}
