/**
 * Copyright (C) 2020 Andrew Rioux
 * 
 * This file is part of Event Manager.
 * 
 * Event Manager is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 2 of the License, or
 * (at your option) any later version.
 * 
 * Event Manager is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * 
 * You should have received a copy of the GNU General Public License
 * along with Event Manager.  If not, see <http://www.gnu.org/licenses/>.
 */

.calendar .events-list .event-item.draft {
	background-color: #151;
	background-image: repeating-linear-gradient(-45deg, #151, #151 10px, #171 10px, #171 20px);
	border-color: #131;
	color: #fff;
}

.calendar .events-list .event-item.team {
	background-color: #51b749;
	border-color: #113;
	color: #fff;
}

.calendar .events-list .event-item.draft.team {
	background-color: #7ae7bf;
	background-image: repeating-linear-gradient(
		-45deg,
		#51b749,
		#51b749 10px,
		#55da5e 10px,
		#55da5e 20px
	);
	color: #000;
}

.calendar .events-list .event-item.tentative {
	background-color: #46d6db;
	border-color: #113;
	color: #000;
}

.calendar .events-list .event-item.cancelled {
	background-color: #dc2127;
	border-color: #faa;
	color: #fff;
}

.calendar .events-list .event-item.info {
	background-color: #a4bdfc;
	border-color: #000;
	color: #000;
}
