/**
 * Copyright (C) 2020 Andrew Rioux
 * 
 * This file is part of Event Manager.
 * 
 * Event Manager is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 2 of the License, or
 * (at your option) any later version.
 * 
 * Event Manager is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * 
 * You should have received a copy of the GNU General Public License
 * along with Event Manager.  If not, see <http://www.gnu.org/licenses/>.
 */

@keyframes svgCircleAnimation {
	0% {
		transform: scale(0);
	}
	50% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}

.svg-loader {
	margin: 0 auto;
	height: 55px;
}

.svg-loader-circle-1 {
	animation: svgCircleAnimation 1.5s linear infinite;
	animation-delay: -1.35s;
	transform-origin: 16.66% center;
}

.svg-loader-circle-2 {
	animation: svgCircleAnimation 1.5s linear infinite;
	animation-delay: -1.2s;
	transform-origin: 33.33% center;
}

.svg-loader-circle-3 {
	animation: svgCircleAnimation 1.5s linear infinite;
	animation-delay: -1.05s;
	transform-origin: center center;
}

.svg-loader-circle-4 {
	animation: svgCircleAnimation 1.5s linear infinite;
	animation-delay: -0.9s;
	transform-origin: 66.66% center;
}

.svg-loader-circle-5 {
	animation: svgCircleAnimation 1.5s linear infinite;
	animation-delay: -0.75s;
	transform-origin: 83.33% center;
}
