/**
 * Copyright (C) 2020 Andrew Rioux
 * 
 * This file is part of Event Manager.
 * 
 * Event Manager is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 2 of the License, or
 * (at your option) any later version.
 * 
 * Event Manager is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * 
 * You should have received a copy of the GNU General Public License
 * along with Event Manager.  If not, see <http://www.gnu.org/licenses/>.
 */

.absentee-form .original-time {
	clear: both;
	margin: 10px 0;
	padding-top: 10px;
}

.absentee-form .submit {
	margin-top: 10px;
	float: right;
}

.absentee-form input[name='comments'] {
	width: 250px;
	margin: 0 auto;
}

.absentee-form .datetime-input-root {
	padding-top: 0;
	margin-bottom: 10px;
}

.absentee-form .formbox {
	clear: both;
	padding-top: 10px;
}
