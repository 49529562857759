/**
 * Copyright (C) 2020 Andrew Rioux
 * 
 * This file is part of Event Manager.
 * 
 * Event Manager is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 2 of the License, or
 * (at your option) any later version.
 * 
 * Event Manager is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * 
 * You should have received a copy of the GNU General Public License
 * along with Event Manager.  If not, see <http://www.gnu.org/licenses/>.
 */
.primaryButton,
.secondaryButton {
	font-family: RobotoRegular, Arial, Helvetica, sans-serif;
	font-size: 14px;
	line-height: 18px;
	color: white;
	text-transform: uppercase;
	border: 0px;
	border-radius: 8px;
	padding: 10px 24px 8px 24px;
	cursor: pointer;
}
.primaryButton:hover,
.secondaryButton:hover {
	background-color: #f6bf22;
	color: #0b375b;
}

.primaryButton {
	background: #4278ba;
}

.secondaryButton {
	background: #07346c;
}

.linkButton {
	padding: 0;
	color: #2875d7;
	cursor: pointer;
	border: 0;
	background: none;
	text-align: left;
	text-decoration: underline;
	font-size: 14px;
	line-height: 14px;
}
.linkButton:hover {
	color: #f6bf22;
}

.primaryButton.disabled,
.secondaryButton.disabled {
	color: black;
	cursor: progress;
}
