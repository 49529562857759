/**
 * Copyright (C) 2020 Andrew Rioux
 * 
 * This file is part of Event Manager.
 * 
 * Event Manager is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 2 of the License, or
 * (at your option) any later version.
 * 
 * Event Manager is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * 
 * You should have received a copy of the GNU General Public License
 * along with Event Manager.  If not, see <http://www.gnu.org/licenses/>.
 */

.selector-filters {
	background: white;
	border: 1px solid black;
	border-bottom: none;
	overflow: auto;
	clear: both;
}

.selector-filters ul {
	list-style-type: none;
	margin: 0;
}

.selector-filters ul li {
	display: flex;
	padding: 5px;
}

.selector-filters ul li .selector-left-filter {
	flex-basis: 220px;
	order: 1;
	padding-top: 9px;
	padding-left: 9px;
	box-sizing: border-box;
}

.selector-filters ul li .selector-right-filter {
	flex: 1;
	order: 2;
}

.selector-values {
	margin: 0 !important;
	background: white;
	border: 1px solid black;
	padding-left: 0;
}

.selector-values li {
	display: block;
	list-style-type: none;
	padding: 5px !important;
	cursor: pointer;
	box-sizing: border-box;
}

.selector-values li.selected {
	background: blue;
	color: white !important;
}
