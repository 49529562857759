/**
 * Copyright (C) 2020 Andrew Rioux
 * 
 * This file is part of Event Manager.
 * 
 * Event Manager is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 2 of the License, or
 * (at your option) any later version.
 * 
 * Event Manager is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * 
 * You should have received a copy of the GNU General Public License
 * along with Event Manager.  If not, see <http://www.gnu.org/licenses/>.
 */

.partTimeSignupDisplay {
	padding-top: 20px;
	margin: 20px;
}

.partTimeSignupDisplay div {
	height: 20px;
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	border-width: 0;
	float: left;
}

.partTimeSignupDisplay .timeBefore,
.partTimeSignupDisplay .timeAfter {
	background: gray;
}

.partTimeSignupDisplay .timeDuring {
	background: #0b375b;
}

.cadetroster {
	background: white;
	width: 100%;
}

@media print {
	header,
	footer,
	aside,
	nav,
	form,
	iframe {
		display: none;
	}
	* {
		color: black;
		background: white;
	}
	table {
		font-size: 80%;
	}
}

.debrieflist table {
	border: 1px solid black;
	border-collapse: collapse;
	box-sizing: border-box;
}
.debrieflist caption,
.debrieflist th {
	width: max-content;
	border: 1px solid black;
	border-collapse: collapse;
	box-sizing: border-box;
	background: lightblue;
}
.debrieflist td {
	border: 1px solid black;
	border-collapse: collapse;
	box-sizing: border-box;
}
