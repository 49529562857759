/**
 * Copyright (C) 2020 Andrew Rioux
 * 
 * This file is part of Event Manager.
 * 
 * Event Manager is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 2 of the License, or
 * (at your option) any later version.
 * 
 * Event Manager is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * 
 * You should have received a copy of the GNU General Public License
 * along with Event Manager.  If not, see <http://www.gnu.org/licenses/>.
 */
/**
 * Copyright (C) 2020 Andrew Rioux
 * 
 * This file is part of Event Manager.
 * 
 * Event Manager is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 2 of the License, or
 * (at your option) any later version.
 * 
 * Event Manager is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * 
 * You should have received a copy of the GNU General Public License
 * along with Event Manager.  If not, see <http://www.gnu.org/licenses/>.
 */
@media screen {
	footer {
		grid-area: footer;
		background: #0f407d;
		margin-top: -50px;
		padding-top: 68px;
		z-index: 1;
		box-shadow: inset 0 30px 30px -25px #002149;
		display: grid;
		grid-template-areas: '. footer .' '. extra .';
		grid-template-columns: 1fr 1028px 1fr;
		grid-template-rows: auto;
	}
	footer .contact {
		grid-area: footer;
		display: flex;
		flex-direction: row;
	}
	footer .contact div {
		flex: 1;
	}
	footer .footerBoxTitle {
		font-family: 'RobotoRegular', Arial, Helvetica, sans-serif;
		font-size: 16px;
		color: #f6bf22;
		line-height: 20px;
		margin: 0 0 7px 0;
	}
	footer .links {
		grid-area: extra;
		border-top: 1px solid #305d95;
		margin-top: 20px;
		padding: 21px 0 0 0;
	}
	footer .links .links-right {
		float: right;
		font-size: 12px;
	}
	footer .links .info-left {
		float: left;
		font-size: 12px;
	}
	footer p {
		font-family: 'RobotoRegular', Arial, Helvetica, sans-serif;
		font-size: 14px;
		color: white;
		line-height: 20px;
		margin: 0 0 10px 0;
	}
	footer .social-media {
		display: inline-block;
		width: 32px;
		height: 32px;
		margin-right: 8px;
		text-decoration: none;
		background-size: 32px 32px;
		background-attachment: initial;
		content: ' ';
		font-size: 0px;
		margin-top: -30px;
	}
	footer .social-media.fb {
		background-image: url('../../images/sm/fb.png');
	}
	footer .social-media.twitter {
		background-image: url('../../images/sm/tw.png');
	}
	footer .social-media.youtube {
		background-image: url('../../images/sm/yt.jpg');
	}
	footer .social-media.linkedin {
		background-image: url('../../images/sm/li.png');
	}
	footer .social-media.instagram {
		background-image: url('../../images/sm/insta.png');
	}
	footer .social-media.flickr {
		background-image: url('../../images/sm/flickr.png');
	}
	footer .social-media.discord {
		background-image: url('../../images/sm/discord.png');
	}
}

@media print {
	footer {
		display: none;
	}
}

@media (max-width: 1028px) {
	footer {
		grid-template-areas: 'footer' 'extra';
		grid-template-columns: 100%;
		grid-template-rows: min-content min-content;
	}
	footer .contact {
		flex-direction: column;
	}
	footer .links .links-right,
	footer .links .info-left {
		float: none;
		clear: both;
		margin: 0 auto;
	}
}
