/**
 * Copyright (C) 2020 Andrew Rioux
 * 
 * This file is part of Event Manager.
 * 
 * Event Manager is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 2 of the License, or
 * (at your option) any later version.
 * 
 * Event Manager is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * 
 * You should have received a copy of the GNU General Public License
 * along with Event Manager.  If not, see <http://www.gnu.org/licenses/>.
 */

@-webkit-keyframes uil-default-anim {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@keyframes uil-default-anim {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.uil-default-css > div:nth-of-type(1) {
	-webkit-animation: uil-default-anim 1s linear infinite;
	animation: uil-default-anim 1s linear infinite;
	-webkit-animation-delay: -0.5s;
	animation-delay: -0.5s;
}
.uil-default-css {
	position: relative;
	background: none;
	width: 200px;
	height: 200px;
}
.uil-default-css > div:nth-of-type(2) {
	-webkit-animation: uil-default-anim 1s linear infinite;
	animation: uil-default-anim 1s linear infinite;
	-webkit-animation-delay: -0.4166666666666667s;
	animation-delay: -0.4166666666666667s;
}
.uil-default-css {
	position: relative;
	background: none;
	width: 200px;
	height: 200px;
}
.uil-default-css > div:nth-of-type(3) {
	-webkit-animation: uil-default-anim 1s linear infinite;
	animation: uil-default-anim 1s linear infinite;
	-webkit-animation-delay: -0.33333333333333337s;
	animation-delay: -0.33333333333333337s;
}
.uil-default-css {
	position: relative;
	background: none;
	width: 200px;
	height: 200px;
}
.uil-default-css > div:nth-of-type(4) {
	-webkit-animation: uil-default-anim 1s linear infinite;
	animation: uil-default-anim 1s linear infinite;
	-webkit-animation-delay: -0.25s;
	animation-delay: -0.25s;
}
.uil-default-css {
	position: relative;
	background: none;
	width: 200px;
	height: 200px;
}
.uil-default-css > div:nth-of-type(5) {
	-webkit-animation: uil-default-anim 1s linear infinite;
	animation: uil-default-anim 1s linear infinite;
	-webkit-animation-delay: -0.16666666666666669s;
	animation-delay: -0.16666666666666669s;
}
.uil-default-css {
	position: relative;
	background: none;
	width: 200px;
	height: 200px;
}
.uil-default-css > div:nth-of-type(6) {
	-webkit-animation: uil-default-anim 1s linear infinite;
	animation: uil-default-anim 1s linear infinite;
	-webkit-animation-delay: -0.08333333333333331s;
	animation-delay: -0.08333333333333331s;
}
.uil-default-css {
	position: relative;
	background: none;
	width: 200px;
	height: 200px;
}
.uil-default-css > div:nth-of-type(7) {
	-webkit-animation: uil-default-anim 1s linear infinite;
	animation: uil-default-anim 1s linear infinite;
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}
.uil-default-css {
	position: relative;
	background: none;
	width: 200px;
	height: 200px;
}
.uil-default-css > div:nth-of-type(8) {
	-webkit-animation: uil-default-anim 1s linear infinite;
	animation: uil-default-anim 1s linear infinite;
	-webkit-animation-delay: 0.08333333333333337s;
	animation-delay: 0.08333333333333337s;
}
.uil-default-css {
	position: relative;
	background: none;
	width: 200px;
	height: 200px;
}
.uil-default-css > div:nth-of-type(9) {
	-webkit-animation: uil-default-anim 1s linear infinite;
	animation: uil-default-anim 1s linear infinite;
	-webkit-animation-delay: 0.16666666666666663s;
	animation-delay: 0.16666666666666663s;
}
.uil-default-css {
	position: relative;
	background: none;
	width: 200px;
	height: 200px;
}
.uil-default-css > div:nth-of-type(10) {
	-webkit-animation: uil-default-anim 1s linear infinite;
	animation: uil-default-anim 1s linear infinite;
	-webkit-animation-delay: 0.25s;
	animation-delay: 0.25s;
}
.uil-default-css {
	position: relative;
	background: none;
	width: 200px;
	height: 200px;
}
.uil-default-css > div:nth-of-type(11) {
	-webkit-animation: uil-default-anim 1s linear infinite;
	animation: uil-default-anim 1s linear infinite;
	-webkit-animation-delay: 0.33333333333333337s;
	animation-delay: 0.33333333333333337s;
}
.uil-default-css {
	position: relative;
	background: none;
	width: 200px;
	height: 200px;
}
.uil-default-css > div:nth-of-type(12) {
	-webkit-animation: uil-default-anim 1s linear infinite;
	animation: uil-default-anim 1s linear infinite;
	-webkit-animation-delay: 0.41666666666666663s;
	animation-delay: 0.41666666666666663s;
}
.uil-default-css {
	position: relative;
	background: none;
	width: 200px;
	height: 200px;
}
