/**
 * Copyright (C) 2020 Andrew Rioux
 * 
 * This file is part of Event Manager.
 * 
 * Event Manager is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 2 of the License, or
 * (at your option) any later version.
 * 
 * Event Manager is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * 
 * You should have received a copy of the GNU General Public License
 * along with Event Manager.  If not, see <http://www.gnu.org/licenses/>.
 */
.radio-group-container {
	margin-bottom: 20px;
}

.radio-button-container {
	position: relative;
	margin: 0 0 10px 0;
}
.radio-button-container input[type='radio'] {
	position: absolute;
	visibility: hidden;
}
.radio-button-container input[type='radio']:checked ~ .check {
	border-color: #666666;
}
.radio-button-container input[type='radio']:checked ~ .check::before {
	background: #666666;
}
.radio-button-container label {
	display: block;
	position: relative;
	font-weight: 300px;
	padding-left: 30px;
	z-index: 3;
	cursor: pointer;
	transition: all 0.25s linear;
	line-height: 22px;
	height: 22px;
}
.radio-button-container .check {
	display: block;
	position: absolute;
	border: 5px solid #aaaaaa;
	border-radius: 100%;
	height: 12px;
	width: 12px;
	top: 0;
	left: 0;
	z-index: 5;
	transition: border 0.25s linear;
	padding-left: 0;
}
.radio-button-container .check::before {
	display: block;
	position: absolute;
	content: '';
	border-radius: 100%;
	height: 10px;
	width: 10px;
	top: 1px;
	left: 1px;
	margin: auto;
	transition: background 0.25s linear;
}
.radio-button-container:hover .check {
	border-color: #999999;
}
