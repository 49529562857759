/**
 * Copyright (C) 2020 Andrew Rioux
 * 
 * This file is part of Event Manager.
 * 
 * Event Manager is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 2 of the License, or
 * (at your option) any later version.
 * 
 * Event Manager is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * 
 * You should have received a copy of the GNU General Public License
 * along with Event Manager.  If not, see <http://www.gnu.org/licenses/>.
 */

.attendanceTable {
	width: 100%;
	border-collapse: collapse;
	box-sizing: border-box;
}

.attendanceTableRow {
	box-sizing: border-box;
}

.attendanceTableHeaderRow {
	box-sizing: border-box;
}

.attendanceTableHeaderRow th,
.attendanceTableRow td {
	box-sizing: border-box;
	padding: 5px;
	border: 1px solid black;
	text-align: left;
}

.overflowEllipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.controlsBox {
	padding: 10px;
	box-sizing: border-box;
}

.controlsBox button {
	margin-right: 10px;
}

.controlsBox .svg-loader {
	width: 100%;
}
