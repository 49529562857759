/**
 * Copyright (C) 2020 Andrew Rioux
 * 
 * This file is part of Event Manager.
 * 
 * Event Manager is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 2 of the License, or
 * (at your option) any later version.
 * 
 * Event Manager is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * 
 * You should have received a copy of the GNU General Public License
 * along with Event Manager.  If not, see <http://www.gnu.org/licenses/>.
 */
.checkboxDiv {
	position: relative;
	margin: 0;
	margin-bottom: 10px;
	line-height: normal;
	clear: both;
	height: 22px;
}
.checkboxDiv input + label {
	width: 20px;
	height: 20px;
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	background: #fcfff4;
	background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
	border-radius: 4px;
	box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
	z-index: 0;
}
.checkboxDiv input + label.disabled {
	background: #7e7f7a;
	background: linear-gradient(to bottom, #7e7f7a 0%, #6f726b 40%, #595f56 100%);
}
.checkboxDiv input + label.disabled:after {
	border-color: #33333333;
}
.checkboxDiv input + label:after {
	content: '';
	width: 9px;
	height: 5px;
	position: absolute;
	top: 4px;
	left: 4px;
	border: 3px solid #333333;
	border-top: none;
	border-right: none;
	background: transparent;
	opacity: 0;
	transform: rotate(-45deg);
	z-index: 0;
}
.checkboxDiv input[type='checkbox'] {
	visibility: hidden;
	position: absolute;
}
.checkboxDiv input[type='checkbox']:checked + label:after {
	opacity: 1;
}
.checkboxDiv input + label + label {
	padding-left: 15px;
	float: left;
	margin-left: 15px;
}
