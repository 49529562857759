/**
 * Copyright (C) 2020 Andrew Rioux
 * 
 * This file is part of Event Manager.
 * 
 * Event Manager is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 2 of the License, or
 * (at your option) any later version.
 * 
 * Event Manager is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * 
 * You should have received a copy of the GNU General Public License
 * along with Event Manager.  If not, see <http://www.gnu.org/licenses/>.
 */

.flightcontactlist-item {
	clear: both;
	padding: 15px;
	border-top: 1px solid black;
}

.flightcontactlist-phones p {
	margin-top: 0px !important;
}

.flightcontactlist-item:nth-of-type(1) {
	border-top-width: 0;
}

.flightcontactlist-name {
	float: left;
	width: 50%;
	padding-bottom: 15px;
}

.flightcontactlist-phones {
	float: left;
}

.flightcontactlist-item .flightcontactlist-absent {
	display: inline-block;
	padding-top: 10px;
	font-style: italic;
	color: red;
}
