/**
 * Copyright (C) 2020 Andrew Rioux
 * 
 * This file is part of Event Manager.
 * 
 * Event Manager is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 2 of the License, or
 * (at your option) any later version.
 * 
 * Event Manager is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * 
 * You should have received a copy of the GNU General Public License
 * along with Event Manager.  If not, see <http://www.gnu.org/licenses/>.
 */
.detailedlistplus {
	list-style-type: none;
	margin: 0;
	padding: 0;
	clear: both;
	overflow: auto;
}
.detailedlistplus .detailedlistplustitle {
	font-size: 2em;
	color: #f6bf22;
	margin: 0;
	margin-bottom: 5px;
}
.detailedlistplus li {
	margin: 0;
	clear: both;
	width: 100%;
	padding-bottom: 5px;
}
.detailedlistplus .detailedlistplusrow {
	width: 100%;
	margin: 0;
	height: 30px;
	padding: 5px;
	box-sizing: border-box;
	position: relative;
}
.detailedlistplus .detailedlistplusarrow {
	position: absolute;
	display: inline-block;
	width: 0;
	height: 0;
	border-top: 7px solid transparent;
	border-left: 10px solid #aaaaaa;
	border-bottom: 7px solid transparent;
	transition: transform 0.25s ease-out;
	left: 10px;
	top: 9px;
	transform: rotate(0deg);
	cursor: pointer;
}
.detailedlistplus .detailedlistplusarrow.down {
	transform: rotate(450deg);
}
.detailedlistplus .detailedlistplusname {
	margin: 0;
	padding: 0;
	float: left;
	box-sizing: border-box;
	padding-left: 25px;
	cursor: pointer;
}
.detailedlistplus .detailedlistplusname.withlink {
	width: 50%;
}
.detailedlistplus .detailedlistpluslink {
	margin: 0;
	padding: 0;
	width: 49.999%;
	float: right;
	text-align: left;
}
.detailedlistplus .detailedlistplusdesc {
	margin: 0;
	font-style: italic;
	display: none;
	float: left;
	padding: 5px;
	box-sizing: border-box;
	font-size: 0.8em;
	width: 100%;
	padding-left: 15px;
}
